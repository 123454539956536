/*
 * 업무구분: 고객
 * 화 면 명: MSPFS111M
 * 화면설명: 고객성향분석결과7010
 * 작 성 일: 2023.04.28
 * 작 성 자: 이태흥
 * 수정일 : 2024-04-08 강현철  사유 : ASR240301005 / 투자자정보분석결과보고서 기능 개선 및 마케팅 수집기간 변경
 */
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">
    <!-- header start -->
    <fs-header ref="fsHeader" :propObj="pHeaderObj"></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top gap40">
          <div class="left column mr0">
            <div class="row w100p gap40">
              <div class="wrap-input row">
                <label class="emphasis"> 실명번호 종류 </label>
                <mo-dropdown ref="cob_rname_gb" :items="lv_commCode.code211" v-model="lv_cob_rname_gb.value" :disabled="lv_cob_rname_gb.disabled" />
              </div>
              <div class="wrap-input row">
                <label class="emphasis"> 실명번호 </label>
                <mo-text-field
                  ref="ed_rname_no1"
                  class="w80"
                  :class="lv_ed_rname_no.error ? 'error' : ''"
                  v-model="lv_ed_rname_no.value1"
                  :disabled="lv_ed_rname_no.disabled"
                  maxlength="6"
                  placeholder="앞 6자리"
                  type="number"
                  @keyup="fn_SearchValid($event, 0)"
                />
                <m-trans-key-input
                  v-if="isMobile && isMtrans"
                  ref="ed_rname_no2"
                  class="w80"
                  type="numberMax7"
                  :class="lv_ed_rname_no.error ? 'error' : ''"
                  v-model="lv_ed_rname_no.value2"
                  :disabled="lv_ed_rname_no.disabled"
                  start="1"
                  end="-1"
                  dialog="Y"
                  :isClear="lv_isClear"
                  @masked-txt="fn_SetMaskedTxt"
                />
                <mo-text-field
                  v-else
                  ref="ed_rname_no2"
                  class="w80"
                  type="password"
                  :class="lv_ed_rname_no.error ? 'error' : ''"
                  v-model="lv_ed_rname_no.value2"
                  :disabled="lv_ed_rname_no.disabled"
                  maxlength="7"
                  placeholder="뒤 7자리"
                  @keyup="fn_SearchValid($event, 0)"
                />
              </div>
            </div>
            <div class="row w100p">
              <div class="wrap-input row">
                <label class="emphasis"> 고객번호 </label>
                <mo-text-field
                  ref="ed_cust_no"
                  class="w130 flex-unset"
                  :class="lv_ed_cust_no.error ? 'error' : ''"
                  v-model="lv_ed_cust_no.value"
                  maxlength="7"
                  type="number"
                  :disabled="lv_ed_cust_no.disabled"
                  @keyup="fn_SearchValid($event, 1)"
                />
                <mo-button class="w61" @click="fn_OpenPopup(0)"> 고객정보 </mo-button>
                <mo-text-field class="w130 flex-unset" v-model="lv_ed_cust_nm.value" disabled />
              </div>
            </div>
          </div>
          <div class="right column gap10">
            <div class="row w100p h28"></div>
            <div class="row w100p">
              <div class="wrap-button row">
                <mo-button @click="fn_Init()"> 초기화 </mo-button>
                <mo-button primary @click="fn_SearchValid()"> 조회 </mo-button>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2 class="table-title">응답결과</h2>
        </div>
        <div class="wrap-desc bg-blue">
          <p>
            <strong class="user-name"> {{ lv_custIvtPrpnsData.cust_nm }} </strong> 고객님의 투자정보를 분석한 결과 <br />
            {{ lv_custIvtPrpnsData.avail_std_dt_text }} 현재 <strong class="result"> {{ lv_custIvtPrpnsData.invs_dipos_nm }} </strong> 에 속합니다.
          </p>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2 class="table-title">투자성향</h2>
        </div>
        <div class="wrap-table h-scroll">
          <table class="table row-type">
            <thead>
              <tr>
                <th class="w150">분석일자 (회차) 분석시간</th>
                <th class="w150">투자성향</th>
                <th class="w100">점수</th>
                <th>특징</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(items, i) in lv_cust_ivt_prpns_list" :key="i">
                <td>
                  {{ items.avail_std_dt_date }} {{ items.tms_info }} <br />
                  {{ items.erzet }}
                </td>
                <td>{{ items.invs_dipos_nm }}</td>
                <td>{{ items.question_result_score }}</td>
                <td class="aL">{{ items.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-desc bottom mt16">
          <p>
            상기 투자정보 분석 결과 보고서는 고객님이 작성하신 투자자정보 확인서를 통해 산출되었습니다. 산출된 투자성향 결과는 고객님의 현재 상황을 고려한 것으로 향후 상황이 현재 시점과 달라지는
            경우에는 투자권유인(플라자FP)과 상의하시기 바랍니다.
          </p>
        </div>
        <div class="wrap-date">
          <p>
            <span class="year">{{ lv_str_today.yyyy }}</span> 년 <span class="month"> {{ lv_str_today.mm }} </span> 월 <span class="day"> {{ lv_str_today.dd }} </span> 일
          </p>
        </div>
        <div class="wrap-button button-page-bottom row">
          <mo-button v-if="lv_isProcess" primary size="large" @click="fn_GoNextPage()" :disabled="lv_btn_confirm"> 확인 </mo-button>
        </div>
      </ur-box-container>
    </main>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <fs-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></fs-alert-popup>

    <!-- popup308 -->
    <msp-fs-308p ref="popup308" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup308Obj"></msp-fs-308p>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'

import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)

import MSPFS308P from '~/src/ui/fs/MSPFS308P' // 고객정보 팝업

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPFS111M',
  screenId: 'MSPFS111M',
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'msp-fs-308p': MSPFS308P
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SetCommCode()
  },
  mounted() {
    this.fn_Init()
    this.$bizUtil.insSrnLog('MSPFS111M')

    // 개인고객등록에서 등록 후 진입한 경우
    if (!FSCommUtil.gfn_isNull(this.$route.params.cob_rname_gb)) {
      setTimeout(() => {
        let type = ''

        if (FSCommUtil.gfn_isNull(this.$route.params.search_type)) {
          type = 0
          this.lv_cob_rname_gb.value = this.$route.params.cob_rname_gb // 실명번호종류
          this.lv_ed_rname_no.value1 = this.$route.params.ed_rname_no[0] // 실명번호 앞자리
          this.lv_ed_rname_no.value2 = this.$route.params.ed_rname_no[1] // 실명번호 뒷자리
        } else {
          if (this.$route.params.search_type == '0') {
            type = 0
            this.lv_cob_rname_gb.value = this.$route.params.cob_rname_gb // 실명번호종류
            this.lv_ed_rname_no.value1 = this.$route.params.ed_rname_no[0] // 실명번호 앞자리
            this.lv_ed_rname_no.value2 = this.$route.params.ed_rname_no[1] // 실명번호 뒷자리
          } else {
            type = 1
            this.lv_ed_cust_no.value = this.$route.params.bfcer_cust_no // 고객번호
          }
        }
        this.fn_SearchValid({ key: 'v' }, type)
      }, 100)
    }
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 공통 객체
      pHeaderObj: {
        title: '고객성향분석결과',
        step: '5'
      },
      pAlertPopupObj: {},
      lv_commCode: {},
      lv_isProcess: this.getStore('fsStore').getters.getState.isProcess,
      lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
      isMtrans: this.getStore('fsStore').getters.getState.isMtrans, // 보안키패드 적용 여부
      lv_isClear: false, // 보안키패드 초기화

      lv_masked_val: '', // 마스크 변수 체크

      // 팝업 객체
      pPopup308Obj: {},

      // 검색조건
      lv_cob_rname_gb: {}, // 실명번호 종류
      lv_ed_rname_no: {}, // 실명번호
      lv_ed_cust_no: {}, // 고객번호
      lv_ed_cust_nm: {}, // 성명

      lv_custSearchType: '', // 고객정보조회 type 세팅(0: 실명번호 / 1: 고객번호)

      // 조회결과
      lv_rlnmData: {}, // 고객정보 실명정보 매핑 데이터
      lv_custIvtPrpnsData: {}, // 고객성향분석결과 응답결과
      lv_cust_ivt_prpns_list: [], // 고객성향분석결과 설문등록 list
      lv_str_today: {}, // 현재날짜

      // button
      lv_btn_confirm: true, // 확인

      /***********************************************************************************
       * F10570167 : 고객정보조회LIST
       * F10570173 : 고객성향분석결과
       ***********************************************************************************/
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj()
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {},
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SetMaskedTxt
     * 설명       : 보안키패드 체크
     ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val
      if (this.lv_masked_val.length >= 7) {
        this.fn_SearchValid({ key: 'v' }, type)
      }
    },

    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      console.log('fn_SetCommCode...')

      let params = [{ cId: '211', dayChkYn: 'N' }]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },

    fn_SetCommCodeResult(pResult) {
      this.lv_commCode = pResult

      let t_codeList = []
      pResult.code211.forEach((item) => {
        if (item.value == '01' || item.value == '04' || item.value == '05') {
          t_codeList.push(item)
        }
      })

      this.lv_commCode.code211 = t_codeList
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init() {
      console.log('[MSPFS111M] fn_Init...')
      console.log('isProcess :: ' + this.lv_isProcess)

      // 조회결과
      this.lv_rlnmData = {} // 고객정보 실명정보 매핑 데이터
      this.lv_custIvtPrpnsData = {} // 고객성향분석결과 매핑 데이터
      this.lv_cust_ivt_prpns_list = [] // 고객성향분석결과 설문등록 list
      this.lv_str_today = {} // 현재날짜

      // 검색조건
      this.lv_cob_rname_gb = FSCommUtil.gfn_dataSet(0, '01') // 실명번호 종류
      this.lv_ed_rname_no = {
        error: false,
        disabled: false,
        value1: '',
        value2: ''
      }

      this.lv_ed_cust_no = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_cust_no') // 고객번호
      this.lv_ed_cust_nm = FSCommUtil.gfn_dataSet(1) // 고객명
      this.lv_custSearchType = '' // 고객정보조회 type 세팅(0: 실명번호 / 1: 고객번호)

      // button
      this.lv_btn_confirm = true // 확인

      // 보안키패드 초기화
      this.lv_isClear = !this.lv_isClear ? true : false

      if (this.lv_isProcess) {
        this.lv_ed_rname_no.error = true
      }
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/

    fn_AlertPopup(type, pPopupObj) {
      if (!FSCommUtil.gfn_isNull(pPopupObj)) {
        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
     * Function명 : fn_OpenPopup
     * 설명       : popup 컴포넌트 호출
     * 타입(type) : 0: 고객정보
     ******************************************************************************/
    fn_OpenPopup(type) {
      switch (type) {
        case 0:
          this.pPopup308Obj.screenId = 'MSPFS111M'
          this.$refs.popup308.fn_Open()
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : popup 컴포넌트에서 callback
     * 타입(type) : 0: 고객정보
     ******************************************************************************/
    fn_Popup_CallBack(type, pData) {
      switch (type) {
        case 0:
          console.log('고객정보 callback...')

          // 고객번호 매핑 후 고객정보조회
          this.lv_ed_cust_no.value = pData
          this.fn_SearchValid({ key: 'v' }, 1)
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_CustInfoListSearch, fn_CustInfoListSearchResult
     * 설명       : 고객정보조회LIST
     ******************************************************************************/
    fn_CustInfoListSearch() {
      console.log('fn_CustInfoListSearch.....')

      let t_data = { bfcer_cust_no: this.lv_ed_cust_no.value }
      if (this.lv_custSearchType == 0) {
        t_data.rlnm_cnf_no_encr = this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2
      }

      FSInfoUtil.commInfo(this, 1, t_data, this.fn_CustInfoListSearchResult)
    },

    fn_CustInfoListSearchResult(pResultData) {
      console.log('fn_CustInfoListSearchResult.....')

      let t_data = pResultData.data.sy100500_O_00VO
      console.log(t_data)

      if (t_data.length > 0) {
        if (this.lv_custSearchType == 0) {
          // 실명번호정보 매핑
          this.lv_rlnmData.rlnm_cnf_no_encr = this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2
          this.lv_rlnmData.rlnm_no_knd_cd = this.lv_cob_rname_gb

          // 고객번호 매핑
          this.lv_rlnmData.bfcer_cust_no = t_data[0].bfcer_cust_no
        } else {
          // 고객명
          this.lv_ed_cust_nm.value = t_data[0].fnd_cust_nm

          // 실명번호정보 매핑
          this.lv_rlnmData.rlnm_cnf_no_encr = t_data[0].rlnm_cnf_no_encr
          this.lv_rlnmData.rlnm_no_knd_cd = t_data[0].bfcer_rlnm_no_knd_cd

          // 고객번호 매핑
          this.lv_rlnmData.bfcer_cust_no = t_data[0].bfcer_cust_no
        }
      } else {
        // 고객번호 초기화
        this.lv_ed_cust_no.value = ''
      }
    },

    /******************************************************************************
     * Function명 : fn_SearchValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_SearchValid(event, type) {
      console.log('fn_SearchValid.....')

      // 조회버튼 클릭시
      if (FSCommUtil.gfn_isNull(type)) {
        if (!FSCommUtil.gfn_isNull(this.lv_ed_rname_no.value1) && !FSCommUtil.gfn_isNull(this.lv_ed_rname_no.value2)) {
          this.lv_cob_rname_gb.error = false
          this.lv_ed_rname_no.error = false

          if (FSCommUtil.gfn_length(this.lv_ed_rname_no.value1) != 6 || !FSCommUtil.gfn_isNum(this.lv_ed_rname_no.value1)) {
            FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
            this.lv_ed_rname_no.error = true
            this.$refs['ed_rname_no1'].focus()
            return
          }

          if (!this.isMobile) {
            if (FSCommUtil.gfn_length(this.lv_ed_rname_no.value2) != 7 || !FSCommUtil.gfn_isNum(this.lv_ed_rname_no.value2)) {
              FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
              this.lv_ed_rname_no.error = true
              this.$refs['ed_rname_no2'].focus()
              return
            }
          } else {
            if (FSCommUtil.gfn_length(this.lv_masked_val) !== 7) {
              FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
              this.lv_ed_rname_no.error = true
              return
            }
          }

          // 고객번호 비활성화
          this.lv_ed_cust_no.disabled = true

          // 고객정보조회 type 세팅(0: 실명번호 / 1: 고객번호)
          this.lv_custSearchType = 0

          // 고객성향분석결과
          this.fn_CustIvtPrpnsSearch()
        } else if (!FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value)) {
          if (FSCommUtil.gfn_length(this.lv_ed_cust_no.value) != 7 || !FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value)) {
            FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
            this.lv_ed_cust_no.error = true
            this.$refs['ed_cust_no'].focus()
            return
          }

          // 실명번호종류/실명번호 비활성화
          this.lv_cob_rname_gb.disabled = true
          this.lv_ed_rname_no.disabled = true

          // 고객정보조회 type 세팅(0: 실명번호 / 1: 고객번호)
          this.lv_custSearchType = 1

          // 고객성향분석결과
          this.fn_CustIvtPrpnsSearch()
        } else {
          let t_popupObj = {
            content: '주민등록번호 또는 고객번호는 필수입력 항목입니다.'
          }
          this.fn_AlertPopup(0, t_popupObj)
        }
      }

      // 실명번호/고객번호 입력시
      if (type == 0) {
        // 실명번호
        if (FSCommUtil.gfn_length(this.lv_ed_rname_no.value1) == 6) {
          if (FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || this.isMobile) {
            this.$refs['ed_rname_no2'].focus()
          }
        }

        if (FSCommUtil.gfn_length(this.lv_ed_rname_no.value1) == 6 && FSCommUtil.gfn_length(this.lv_ed_rname_no.value2) == 7) {
          this.lv_cob_rname_gb.error = false
          this.lv_ed_rname_no.error = false

          if (!FSCommUtil.gfn_isNum(this.lv_ed_rname_no.value1)) {
            FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
            this.lv_ed_rname_no.error = true
            this.$refs['ed_rname_no1'].focus()
            return
          }

          if (!this.isMobile) {
            if (FSCommUtil.gfn_length(this.lv_ed_rname_no.value2) != 7 || !FSCommUtil.gfn_isNum(this.lv_ed_rname_no.value2)) {
              FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
              this.lv_ed_rname_no.error = true
              this.$refs['ed_rname_no2'].focus()
              return
            }
          } else {
            if (FSCommUtil.gfn_length(this.lv_masked_val) !== 7) {
              FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
              this.lv_ed_rname_no.error = true
              return
            }
          }

          if (FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || this.isMobile) {
            // 실명번호종류/실명번호 비활성화
            this.lv_ed_cust_no.disabled = true

            // 고객정보조회 type 세팅(0: 실명번호 / 1: 고객번호)
            this.lv_custSearchType = type

            // 고객정보조회
            this.fn_CustInfoListSearch()
          }
        }
      } else if (type == 1) {
        // 고객번호
        if (FSCommUtil.gfn_length(this.lv_ed_cust_no.value) == 7) {
          this.lv_ed_cust_no.error = false

          if (!FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value)) {
            FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
            this.lv_ed_cust_no.error = true
            this.$refs['ed_cust_no'].focus()
            return
          } else {
            if (FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || this.isMobile) {
              // 실명번호종류/실명번호 비활성화
              this.lv_cob_rname_gb.disabled = true
              this.lv_ed_rname_no.disabled = true

              // 고객정보조회 type 세팅(0: 실명번호 / 1: 고객번호)
              this.lv_custSearchType = type

              // 고객정보조회
              this.fn_CustInfoListSearch()
            }
          }
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_CustIvtPrpnsSearch, fn_CustIvtPrpnsSearchResult
     * 설명       : 고객성향분석결과
     ******************************************************************************/
    async fn_CustIvtPrpnsSearch() {
      console.log('fn_CustIvtPrpnsSearch.....')

      let t_rlnm_cnf_no_encr = ''
      if (FSCommUtil.gfn_isNull(this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2) == false) {
        t_rlnm_cnf_no_encr = this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2
      }

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570173_S'
      this.eaiCommObj.params = {
        bfcer_cust_no: this.lv_ed_cust_no.value, // 수익증권고객번호
        rlnm_cnf_no_encr: t_rlnm_cnf_no_encr, // 실명확인번호
        rlnm_no_knd_cd: this.lv_cob_rname_gb.value // 실명확인번호구분코드
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CustIvtPrpnsSearchResult)
    },

    fn_CustIvtPrpnsSearchResult(pResultData) {
      console.log('fn_CustIvtPrpnsSearchResult.....')

      let t_data = pResultData.data
      console.log(t_data.cs701000_O_00VO)

      if (t_data.cs701000_O_00VO.length > 0) {
        this.lv_custIvtPrpnsData = t_data.cs701000_O_00VO[0]

        let t_yyyy = FSCommUtil.gfn_substr(this.lv_custIvtPrpnsData.avail_std_dt, 0, 4)
        let t_mm = FSCommUtil.gfn_substr(this.lv_custIvtPrpnsData.avail_std_dt, 4, 2)
        let t_dd = FSCommUtil.gfn_substr(this.lv_custIvtPrpnsData.avail_std_dt, 6, 2)
        this.lv_custIvtPrpnsData.avail_std_dt_text = t_yyyy + '년 ' + t_mm + '월 ' + t_dd + '일'

        // 초기화
        this.lv_cust_ivt_prpns_list = []

        t_data.cs701000_O_00VO.forEach((item, idx) => {
          if (idx < 2) {
            // 2024-04-08 ASR240301005 / 투자자정보분석결과보고서 기능 개선 및 마케팅 수집기간 변경
            item.erzet = item.erzet.substring(0, 2) + ':' + item.erzet.substring(2, 4) + ':' + item.erzet.substring(4, 6)
            item.avail_std_dt_date = FSCommUtil.gfn_dateReplace(1, item.avail_std_dt)
            this.lv_cust_ivt_prpns_list.push(item)
          }
        })

        // 확인 버튼 활성화
        this.lv_btn_confirm = false

        // 현재날짜 세팅
        let t_today = FSCommUtil.gfn_strToday(1)
        this.lv_str_today.yyyy = FSCommUtil.gfn_substr(t_today, 0, 4)
        this.lv_str_today.mm = FSCommUtil.gfn_substr(t_today, 4, 2)
        this.lv_str_today.dd = FSCommUtil.gfn_substr(t_today, 6, 2)
      }
    },

    /******************************************************************************
     * Function명 : fn_GoNextPage
     * 설명       : 판매자료조회 페이지로 이동
     ******************************************************************************/
    fn_GoNextPage() {
      this.$router.push({
        name: 'MSPFS202M',
        params: {
          cob_rname_gb: this.$route.params.cob_rname_gb,
          ed_rname_no: this.$route.params.ed_rname_no,
          bfcer_cust_no: this.$route.params.bfcer_cust_no,
          search_type: this.$route.params.search_type
        }
      })
    }
  }
}
</script>
<style scoped></style>
